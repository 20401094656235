import React, { useState } from 'react';

type PasswordTogglerProps = {
  formElement: HTMLInputElement;
  showLabel: string;
  hideLabel: string;
};

type ButtonProps = {
  onClick: () => void;
  label: string;
};

type HideButtonProps = ButtonProps & {
  formElement: HTMLInputElement;
};

const ShowButton = ({ onClick, label }: ButtonProps) => (
  <button type="button" className="btn btn-default btn-block" onClick={onClick}>
    {label}
  </button>
);

const HideButton = ({ onClick, label }: ButtonProps) => (
  <button type="button" className="btn btn-link btn-xs" onClick={onClick}>
    {label}
  </button>
);

/* eslint-disable react/no-danger */
const HideButtonWithForm = ({
  onClick,
  formElement,
  label,
}: HideButtonProps) => (
  <>
    <div dangerouslySetInnerHTML={{ __html: formElement.outerHTML }} />
    <div className="text-right">
      <HideButton onClick={onClick} label={label} />
    </div>
  </>
);
/* eslint-enable react/no-danger */

export const PasswordToggler = (props: PasswordTogglerProps) => {
  const [active, setActive] = useState(false);
  const { formElement, showLabel, hideLabel } = props;

  const curryActive = (doActive: boolean) => () => setActive(doActive);

  if (active) {
    return (
      <HideButtonWithForm
        onClick={curryActive(false)}
        formElement={formElement}
        label={hideLabel}
      />
    );
  }

  return <ShowButton onClick={curryActive(true)} label={showLabel} />;
};
