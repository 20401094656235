import React from 'react';
import ReactDOM from 'react-dom';

import { WysiwygEditor } from '@/components/molecules/WysiwygEditor';
import { ImageUpload } from './ImageUpload';

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector<HTMLInputElement>(
    '[data-behavior="wysiwyg-editor"]',
  );
  if (!element) {
    return;
  }

  const { props } = element.dataset;
  const args = JSON.parse(props || '{}');

  const imageUpload = new ImageUpload(
    args.imagesUploadUrl,
    args.blobUrlTemplate,
  );
  const imagesUploadHandler = imageUpload.handler;

  ReactDOM.render(
    <WysiwygEditor images_upload_handler={imagesUploadHandler} {...args} />,
    element,
  );
});
