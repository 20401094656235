import { Controller } from '@hotwired/stimulus';
import { client } from '@/modules/client';
import $ from 'jquery';
import 'select2';
import {
  BlocksByCourseIdDocument,
  Block,
} from '@/generated/graphql';

// Connects to data-controller="study_log--search-form"
export default class extends Controller {
  // @ts-ignore
  declare courseIdValue;

  // @ts-ignore
  declare blockIdValue;

  static values = {
    courseId: String,
    blockId: String,
  };

  static targets = ['courseId', 'chapterId', 'submit'];

  connect() {
    $(this.courseIdTarget)
      .select2()
      .on('change', this.onCourseIdChange)
      .trigger('change');
  }

  onCourseIdChange = async (event: JQuery.TriggeredEvent) => {
    this.updateSubmitButtonDisabled();

    $(this.chapterIdTarget).empty().trigger('change');

    const result = await this.blocksByCourseId(event.target.value);

    this.updateChapterOptions(result.data.blocksByCourseId);
  };

  blocksByCourseId = (courseId: string) => client.query({
    query: BlocksByCourseIdDocument,
    variables: {
      id: courseId,
    },
  });

  updateSubmitButtonDisabled() {
    this.submitTarget.disabled = !this.courseIdTarget.value;
  }

  updateChapterOptions(blocks: Block[]) {
    blocks.forEach((block: Block) => {
      const option = new Option(`${'\u00a0\u00a0'.repeat(block.depth)}${block.title}`, block.id, false, false);
      if (!block.isChapter) {
        option.setAttribute('disabled', 'disabled');
      }
      $(this.chapterIdTarget).append(option);
    });
    $(this.chapterIdTarget).val(this.blockIdValue).trigger('change');
  }

  get courseIdTarget(): HTMLSelectElement {
    return this.targets.find('courseId') as HTMLSelectElement;
  }

  get chapterIdTarget(): HTMLSelectElement {
    return this.targets.find('chapterId') as HTMLSelectElement;
  }

  get submitTarget(): HTMLButtonElement {
    return this.targets.find('submit') as HTMLButtonElement;
  }
}
