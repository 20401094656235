import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Alert, Input } from 'reactstrap';
import { createUseStyles } from 'react-jss';
import { Card } from './Card';
import { Section } from './Section';
import { Add } from './Add';
import { arrows as arrowsStyle } from './styles';
import {
  parse,
  stringify,
  excludeKeys,
  generateElementComponentsFromSchemas,
  checkForUnsupportedFeatures,
  addCardObj,
  countElementsFromSchema,
  generateCategoryHash,
  onDragEnd,
  mergeDeep,
  fetchLabel,
} from './utils';
import { DEFAULT_FORM_INPUTS } from './defaults/defaultFormInputs';
import type {
  Mods,
} from './types';

const useStyles = createUseStyles({
  formBuilder: {
    'text-align': 'center',
    '& .fa': {
      cursor: 'pointer',
    },
    '& .fa-question-circle': {
      color: 'gray',
    },
    '& .fa-asterisk': {
      'font-size': '.9em',
      color: 'green',
    },
    '& .fa-square-plus': {
      color: 'green',
      'font-size': '1.5em',
      margin: '0 auto',
    },
    ...arrowsStyle,
    '& .card-container': {
      '&:hover': {
        border: '1px solid green',
      },
      display: 'block',
      'min-width': '400px',
      margin: '2em auto',
      border: '1px solid gray',
      'border-radius': '4px',
      'background-color': 'white',
      '& h4': {
        width: '100%',
        'text-align': 'left',
        display: 'inline-block',
        color: '#138AC2',
        margin: '0.25em .5em 0 .5em',
        'font-size': '18px',
      },
      '& .d-flex': {
        'border-bottom': '1px solid gray',
      },
      '& .label': {
        float: 'left',
      },
    },
    '& .card-container:hover': { border: '1px solid green' },
    '& .card-dependent': {
      border: '1px dashed gray',
    },
    '& .card-requirements': {
      border: '1px dashed black',
    },
    '& .section-container': {
      '&:hover': {
        border: '1px solid green',
      },
      display: 'block',
      width: '90%',
      'min-width': '400px',
      margin: '2em auto',
      border: '1px solid gray',
      'border-radius': '4px',
      'background-color': 'white',
      '& h4': {
        width: '100%',
        'text-align': 'left',
        display: 'inline-block',
        color: '#138AC2',
        margin: '0.25em .5em 0 .5em',
        'font-size': '18px',
      },
      '& .d-flex': {
        'border-bottom': '1px solid gray',
      },
      '& .label': {
        float: 'left',
      },
    },
    '& .section-container:hover': { border: '1px solid green' },
    '& .section-dependent': {
      border: '1px dashed gray',
    },
    '& .section-requirements': {
      border: '1px dashed black',
    },
    '& .alert': {
      textAlign: 'left',
      margin: '1em auto',
      '& h5': {
        color: 'black',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '0',
      },
      '& .fa': { fontSize: '14px' },
    },
    '& .disabled-unchecked-checkbox': {
      color: 'gray',
      '& div::before': { backgroundColor: 'lightGray' },
    },
    '& .disabled-input': {
      '& input': { backgroundColor: 'lightGray' },
      '& input:focus': {
        backgroundColor: 'lightGray',
        border: '1px solid gray',
      },
    },
  },
  formHead: {
    display: 'block',
    margin: '0 auto',
    'background-color': '#EBEBEB',
    border: '1px solid #858F96',
    'border-radius': '4px',
    padding: '10px',
    '& div': {
      'text-align': 'left',
      padding: '10px',
    },
    '& .form-title': {
      'text-align': 'left',
    },
    '& .form-description': {
      'text-align': 'left',
    },
    '& h5': {
      'font-size': '14px',
      'line-height': '21px',
      'font-weight': 'bold',
    },
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    '& .fa-pencil-alt': {
      border: '1px solid #1d71ad',
      color: '#1d71ad',
    },
    '& .modal-body': {
      maxHeight: '500px',
      overflowY: 'scroll',
    },
    '& .card-add': {
      cursor: 'pointer',
      display: 'block',
      color: '$green',
      fontSize: '1.5em',
    },
  },
  formFooter: {
    marginTop: '1em',
    textAlign: 'center',
    '& .fa': { cursor: 'pointer', color: '$green', fontSize: '1.5em' },
  },
});

type FormBuilderProps = {
  schema: string;
  uischema: string;
  onChange: (newSchema: string, newUischema: string) => void;
  mods?: Mods,
  className?: string,
  isReadOnly?: boolean,
};

export const FormBuilder = ({
  schema,
  uischema,
  onChange,
  mods = {},
  className,
  isReadOnly
}: FormBuilderProps) => {
  const classes = useStyles();
  const schemaData: Record<string, any> = parse(schema) || {};
  const uiSchemaData: Record<string, any> = parse(uischema) || {};
  const allFormInputs = mergeDeep(
    excludeKeys({
      ...DEFAULT_FORM_INPUTS,
      ...(mods && mods.customFormInputs) || {},
    },
    mods && mods.deactivatedFormInputs),
    (mods && mods.formInputsOverride) || {});

  const unsupportedFeatures: string[] = checkForUnsupportedFeatures(
    schemaData,
    uiSchemaData,
    allFormInputs,
  );

  const elementNum = countElementsFromSchema(schemaData);
  const defaultCollapseStates = [...Array(elementNum)].map(() => false);
  const [cardOpenArray, setCardOpenArray] = React.useState(
    defaultCollapseStates,
  );
  const categoryHash = generateCategoryHash(allFormInputs);

  return (
    <div className={`${classes.formBuilder} ${className || ''}`}>
      <Alert
        style={{
          display: unsupportedFeatures.length === 0 ? 'none' : 'block',
        }}
        color="warning"
      >
        <h5>Unsupported Features:</h5>
        {unsupportedFeatures.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{message}</li>
        ))}
      </Alert>
      {(!mods || mods.showFormHead !== false) && (
        <div className={classes.formHead} data-test="form-head">
          <div className="form-group">
            <h5 data-test="form-name-label">
              {fetchLabel('formNameLabel', 'Form Name', mods)}
            </h5>
            <Input
              value={schemaData.title || ''}
              placeholder="Title"
              type="text"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                onChange(
                  stringify({
                    ...schemaData,
                    title: ev.target.value,
                  }),
                  uischema,
                );
              }}
              readOnly
              className="form-title"
            />
          </div>
          <div className="form-group">
            <h5 data-test="form-description-label">
              {fetchLabel('formDescriptionLabel', 'Form Description', mods)}
            </h5>
            <Input
              value={schemaData.description || ''}
              placeholder="Description"
              type="textarea"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                onChange(
                  stringify({
                    ...schemaData,
                    description: ev.target.value,
                  }),
                  uischema,
                )}
              readOnly
              className="form-description"
            />
          </div>
        </div>
      )}
      <div className={`form-body ${classes.formBody}`}>
        <DragDropContext
          onDragEnd={(result) =>
            onDragEnd(result, {
              schema: schemaData,
              uischema: uiSchemaData,
              onChange: (newSchema: any, newUiSchema: any) =>
                onChange(stringify(newSchema), stringify(newUiSchema)),
              definitionData: schemaData.definitions,
              definitionUi: uiSchemaData.definitions,
              categoryHash,
            })}
        >
          <Droppable droppableId="droppable">
            {(providedDroppable) => (
              <div
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
              >
                {generateElementComponentsFromSchemas({
                  schemaData,
                  uiSchemaData,
                  onChange: (newSchema: any, newUiSchema: any) =>
                    onChange(stringify(newSchema), stringify(newUiSchema)),
                  definitionData: schemaData.definitions,
                  definitionUi: uiSchemaData.definitions,
                  path: 'root',
                  cardOpenArray,
                  setCardOpenArray,
                  allFormInputs,
                  mods,
                  categoryHash,
                  Card,
                  Section,
                  isReadOnly,
                }).map((element: any, index: number) => (
                  <Draggable
                    key={element.key}
                    draggableId={element.key}
                    index={index}
                    isDragDisabled={isReadOnly}
                  >
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {element}
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={`form-footer ${classes.formFooter}`}>
        <Add
          addElem={() => {
            addCardObj({
              schema: schemaData,
              uischema: uiSchemaData,
              mods,
              onChange: (newSchema: Record<string, any>, newUiSchema: Record<string, any>) =>
                onChange(stringify(newSchema), stringify(newUiSchema)),
              definitionData: schemaData.definitions,
              definitionUi: uiSchemaData.definitions,
              categoryHash,
            });
          }}
          label={mods &&
            mods.labels &&
            typeof mods.labels.addCardLabel === 'string' ? mods.labels.addCardLabel : 'Add Card'}
          hidden={
            schemaData.properties &&
            Object.keys(schemaData.properties).length !== 0
          }
        />
      </div>
    </div>
  );
};
