import React from 'react';
import ReactDOM from 'react-dom';
import { PasswordToggler } from '@/components/molecules/PasswordToggler';

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector<HTMLInputElement>(
    '[data-behavior="password-toggler"]',
  );
  if (!element) {
    return;
  }

  const container = document.createElement('div');
  // replaceWith is not suppoerted for IE
  element.parentNode?.replaceChild(container, element);

  const showLabel = element.dataset.showLabel || 'set data-show-label';
  const hideLabel = element.dataset.hideLabel || 'set data-hide-label';

  ReactDOM.render(
    <PasswordToggler
      formElement={element}
      showLabel={showLabel}
      hideLabel={hideLabel}
    />,
    container,
  );
});
