import { DirectUpload } from '@rails/activestorage';

import { ImageUploadHandler } from './ImageUploadHandler';

interface ImageUploadProps {
  images_upload_url: string;
  blob_url_template: string;
}

export class ImageUpload implements ImageUploadProps {
  images_upload_url: string;

  blob_url_template: string;

  constructor(images_upload_url: string, blob_url_template: string) {
    this.images_upload_url = images_upload_url;
    this.blob_url_template = blob_url_template;
  }

  handler = (
    blobInfo: BlobInfo,
    success: (url: string) => void,
    failure: (err: string, options?: UploadFailureOptions) => void,
  ) => {
    const handler = new ImageUploadHandler(
      success,
      failure,
      this.blob_url_template,
    );

    const directUpload = new DirectUpload(
      blobInfo.blob() as File,
      this.images_upload_url,
      handler,
    );

    directUpload.create(handler.directUploadDidComplete.bind(handler));
  };
}
