import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // @ts-ignore
  declare defaultSubjectValue;

  // @ts-ignore
  declare defaultBodyValue;

  // @ts-ignore
  declare confirmMessageValue;

  static values = {
    defaultSubject: String,
    defaultBody: String,
    confirmMessage: String,
  };

  static targets = [
    'subject',
    'body',
    'autoSendDisabled',
    'autoSendEnabled',
    'xDaysInAdvance',
    'progressRate',
  ];

  reset(event: Event) {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    if (!window.confirm(this.confirmMessageValue)) {
      return;
    }

    this.resetAllField();
  }

  rangeProgressRate() {
    if (Number(this.progressRateTarget.value) > 100) {
      this.progressRateTarget.value = '100'
    } else if (Number(this.progressRateTarget.value) < 1) {
      this.progressRateTarget.value = '1'
    }
  }

  resetAllField() {
    this.subjectTarget.value = this.defaultSubjectValue;
    this.bodyTarget.value = this.defaultBodyValue;
    this.autoSendDisabledTarget.checked = true;
    this.autoSendEnabledTarget.checked = false;
    if (this.targets.has('xDaysInAdvance')) {
      this.xDaysInAdvanceTarget.value = '1';
    };
    if (this.targets.has('progressRate')) {
      this.progressRateTarget.value = '1';
    };
  }

  get subjectTarget() {
    return this.targets.find('subject') as HTMLInputElement;
  }

  get bodyTarget() {
    return this.targets.find('body') as HTMLInputElement;
  }

  get autoSendDisabledTarget() {
    return this.targets.find('autoSendDisabled') as HTMLInputElement;
  }

  get autoSendEnabledTarget() {
    return this.targets.find('autoSendEnabled') as HTMLInputElement;
  }

  get xDaysInAdvanceTarget() {
    return this.targets.find('xDaysInAdvance') as HTMLInputElement;
  }

  get progressRateTarget() {
    return this.targets.find('progressRate') as HTMLInputElement;
  }
}
