import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from '@hotwired/stimulus';

import { SurveyChart } from '@/components/organisms/SurveyChart';

export default class extends Controller {
  // @ts-ignore
  declare chartValue;

  static values = {
    chart: Object,
  };

  static targets = ['canvas'];

  connect() {
    ReactDOM.render(<SurveyChart data={this.chartValue} />, this.canvas);
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.canvas);
  }

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
