import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static targets = ['checkbox', 'checkAll', 'uncheckAll', 'checkedAny'];

  connect() {
    this.checkAllTarget.addEventListener('click', this.checkAll);
    this.uncheckAllTarget.addEventListener('click', this.uncheckAll);
    this.checkboxTargets.forEach((checkbox) => checkbox.addEventListener('change', this.refresh));
    this.refresh();
  }

  disconnect() {
    this.checkAllTarget.removeEventListener('click', this.checkAll);
    this.uncheckAllTarget.removeEventListener('click', this.uncheckAll);
    this.checkboxTargets.forEach((checkbox) => checkbox.removeEventListener('change', this.refresh));
  }

  checkAll = (e: Event) => {
    e.preventDefault();
    this.checkboxTargets.forEach((checkbox) => {
      /* eslint no-param-reassign: 0 */
      checkbox.checked = true;
      this.triggerInputEvent(checkbox);
    });
    this.refresh();
  };

  uncheckAll = (e: Event) => {
    e.preventDefault();
    this.checkboxTargets.forEach((checkbox) => {
      /* eslint no-param-reassign: 0 */
      checkbox.checked = false;
      this.triggerInputEvent(checkbox);
    });
    this.refresh();
  };

  triggerInputEvent = (checkbox: HTMLInputElement) => {
    const event = new Event('input', { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  };

  refresh = () => {
    const isInstructor =this.checkedAnyTarget.getAttribute('data-isInstructor') === 'true';
    const permissionEditableContent = this.checkedAnyTarget.getAttribute('data-permissionEditableContent') === 'true';

    if (isInstructor) {
      if (permissionEditableContent) {
        this.assignValues();
      } else {
        this.checkAllTarget.disabled = true;
        this.uncheckAllTarget.disabled = true;
        this.checkedAnyTarget.disabled = true;
      }
    } else {
      this.assignValues();
    }
  };

  assignValues = () => {
    const checkboxesCount = this.checkboxTargets.length;

    this.checkAllTarget.disabled = this.checked.length === checkboxesCount;
    this.uncheckAllTarget.disabled = this.checked.length === 0;
    this.checkedAnyTarget.disabled = this.checked.length === 0;
  };

  get checked(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get checkboxTargets(): HTMLInputElement[] {
    return this.targets.findAll('checkbox') as HTMLInputElement[];
  }

  get checkAllTarget(): HTMLInputElement {
    return this.targets.find('checkAll') as HTMLInputElement;
  }

  get uncheckAllTarget(): HTMLInputElement {
    return this.targets.find('uncheckAll') as HTMLInputElement;
  }

  get checkedAnyTarget(): HTMLInputElement {
    return this.targets.find('checkedAny') as HTMLInputElement;
  }
}
