import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from '@hotwired/stimulus';

import { SurveyPreview } from '@/components/organisms/SurveyPreview';

export default class extends Controller {
  // @ts-ignore
  declare schemaValue;

  // @ts-ignore
  declare uiSchemaValue;

  // @ts-ignore
  declare formDataValue;

  static values = {
    schema: Object,
    uiSchema: Object,
    formData: Object,
  };

  static targets = ['schema', 'uiSchema', 'canvas'];

  connect() {
    ReactDOM.render(
      <SurveyPreview
        schema={this.schemaValue}
        uiSchema={this.uiSchemaValue}
        formData={this.formDataValue}
        readonly
      />,
      this.canvas,
    );
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.canvas);
  }

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
