import React, { useState } from 'react';

import { FormBuilder } from './SurveyBuilder/FormBuilder';
import {
  Mods,
} from './SurveyBuilder/types';

type SurveyBuilderProps = {
  schema: string;
  uischema: string;
  onChange: (schema: string, uiSchema: string) => void;
  labels: Mods['labels'];
  tooltipDescriptions: Mods['tooltipDescriptions'];
  newElementTitle: string;
  formInputsOverride?: Record<string, any>;
  isReadOnly: boolean
};

export const SurveyBuilder = ({
  schema: initialSchema,
  uischema: initialUiSchema,
  onChange,
  labels,
  tooltipDescriptions,
  newElementTitle,
  formInputsOverride,
  isReadOnly,
}: SurveyBuilderProps) => {
  const mods = {
    deactivatedFormInputs: ['array', 'time', 'date', 'dateTime', 'integer', 'number', 'password', 'checkbox'],
    labels,
    tooltipDescriptions,
    newElementDefaultDataOptions: {
      title: newElementTitle,
    },
    formInputsOverride,
    defaultInputName: 'input',
    formInputsOrder: [
      'shortAnswer',
      'longAnswer',
      'dropdown',
      'radio',
      'checkboxes',
    ],
  };

  const [schema, setSchema] = useState(initialSchema);
  const [uischema, setUischema] = useState(initialUiSchema);

  return (
    <FormBuilder {...{
      schema,
      uischema,
      mods,
      onChange: (newSchema: string, newUiSchema: string) => {
        setSchema(newSchema);
        setUischema(newUiSchema);
        onChange(newSchema, newUiSchema);
      },
      isReadOnly
    }}
    />
  );
};
