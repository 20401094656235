import React from 'react';
import Form from '@rjsf/core';

type Props = {
  schema: any;
  uiSchema: any;
  formData?: any;
  readonly?: boolean;
};

const validator = (uiSchema: any) => (formData: any, errors: any) => {
  Object.keys(formData).forEach((key: string) => {
    if (uiSchema[key]) {
      const uiOptions = uiSchema[key]['ui:options'];
      if (uiOptions && uiOptions.maxRows) {
        const lines = formData[key].split(/\r\n|\r|\n/);
        if (lines.length > uiOptions.maxRows) {
          errors[key].addError(
            `${key} must be less than ${uiOptions.maxRows} lines`,
          );
        }
      }
    }
  });
  return errors;
};

export const SurveyPreview = ({
  schema,
  uiSchema,
  formData,
  readonly,
}: Props) => (
  <Form
    schema={schema}
    uiSchema={uiSchema}
    formData={formData}
    validate={validator(uiSchema)}
    liveValidate={!readonly}
    readonly={readonly}
  >
    <div />
  </Form>
);
