import { ApolloClient, InMemoryCache } from '@apollo/client';

const token =
  document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ||
  '';

export const client = new ApolloClient({
  uri: '/graphql',
  headers: {
    'X-CSRF-Token': token,
  },
  cache: new InMemoryCache(),
});
