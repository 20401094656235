import React, { useState, useEffect } from 'react';
import {
  Input,
  FormFeedback,
} from 'reactstrap';
import * as yup from 'yup';
import {
  excludeKeys,
  generateElementComponentsFromSchemas,
  generateCategoryHash,
  getRandomId,
  fetchLabel,
} from '../utils';
import { Card } from '../Card';
import { Section } from '../Section';
import { FBCheckbox } from '../checkbox/FBCheckbox';
import { shortAnswerInputs } from './shortAnswerInputs';
import { longAnswerInputs } from './longAnswerInputs';
import { numberInputs } from './numberInputs';
import { defaultInputs, MultipleChoice } from './defaultInputs';
import type {
  CardBody, Parameters, Mods, FormInput,
} from '../types';

type Props = {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
};

/* eslint-disable no-template-curly-in-string */
const validate = async ({
  parameters,
  mods,
}: {
  parameters: Parameters,
  mods?: Mods,
}) => {
  yup.setLocale({
    number: {
      min: fetchLabel('validations.checkboxes.min', 'must be greater than or equal to ${max}', mods),
      max: fetchLabel('validations.checkboxes.max', 'must be less than or equal to ${max}', mods),
    },
  });

  const arrayParameterSchema = yup.object().shape({
    minItems: yup.number().integer().min(0)
      .max(parameters.items.enum.length)
      .nullable(),
    maxItems: yup.number().integer().min(yup.ref('minItems'))
      .max(parameters.items.enum.length)
      .nullable(),
  });

  return arrayParameterSchema.validate(parameters);
};

// specify the inputs required for a string type object
const CardArrayParameterInputs: React.FC<Props> = ({
  parameters,
  onChange,
}) => (
  <div>
    <h4>Minimum Items</h4>
    <Input
      value={parameters.minItems || ''}
      placeholder="ex: 2"
      key="minimum"
      type="number"
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...parameters,
          minItems: parseInt(ev.target.value, 10),
        });
      }}
      className="card-modal-number"
    />
    <h4>Maximum Items</h4>
    <Input
      value={parameters.maxItems || ''}
      placeholder="ex: 2"
      key="maximum"
      type="number"
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...parameters,
          maxItems: parseInt(ev.target.value, 10),
        });
      }}
      className="card-modal-number"
    />
  </div>
);

type InnerCardComponentPorps = {
  defaultFormInputs: Record<string, FormInput>;
};

/* eslint-disable react/no-unstable-nested-components */
function getInnerCardComponent({
  defaultFormInputs,
}: InnerCardComponentPorps): CardBody {
  return ({
    parameters,
    onChange,
    mods,
  }: {
    parameters: Parameters;
    onChange: (newParams: Parameters) => void;
    mods?: Mods;
  }) => {
    const [elementId] = useState(getRandomId);
    const newDataProps: Record<string, any> = {};
    const newUiProps: Record<string, any> = {};
    const allFormInputs = excludeKeys(
      {

        ...defaultFormInputs,
        ...(mods && mods.customFormInputs) || {},
      },
      mods && mods.deactivatedFormInputs,
    );
    // parse components into data and ui relevant pieces
    Object.keys(parameters).forEach((propName) => {
      if (propName.startsWith('ui:*')) {
        newUiProps[propName.substring(4)] = parameters[propName];
      } else if (propName.startsWith('ui:')) {
        newUiProps[propName] = parameters[propName];
      } else if (!['name', 'required'].includes(propName)) {
        newDataProps[propName] = parameters[propName];
      }
    });
    const definitionData = parameters.definitionData
      ? parameters.definitionData
      : {};
    const definitionUi = parameters.definitionUi
      ? parameters.definitionUi
      : {};
    const [cardOpen, setCardOpen] = React.useState(false);

    if (parameters.type !== 'array') {
      return <h4>Not an array </h4>;
    }

    return (
      <div className="card-array">
        <FBCheckbox
          onChangeValue={() => {
            if (newDataProps.items.type === 'object') {
              onChange({
                ...parameters,
                items: {
                  ...newDataProps.items,
                  type: 'string',
                },
              });
            } else {
              onChange({
                ...parameters,
                items: {
                  ...newDataProps.items,
                  type: 'object',
                },
              });
            }
          }}
          isChecked={newDataProps.items.type === 'object'}
          label="Section"
          id={`${elementId}_issection`}
        />
        {generateElementComponentsFromSchemas({
          schemaData: {
            properties: {
              item: newDataProps.items,
            },
          },
          uiSchemaData: {
            item: newUiProps.items,
          },
          onChange: (schema, uischema) => {
            onChange({
              ...parameters,
              items: schema.properties.item,
              'ui:*items': uischema.item || {},
            });
          },
          path: elementId,
          definitionData,
          definitionUi,
          hideKey: true,
          cardOpenArray: [cardOpen],
          setCardOpenArray: (newArr) => setCardOpen(newArr[0]),
          allFormInputs,
          mods,
          categoryHash: generateCategoryHash(allFormInputs),
          Card: (props: any) => (
            <Card {...props} showObjectNameInput={false} />
          ),
          Section,
        })}
      </div>
    );
  };
}

export const CheckboxesChoice = ({
  parameters,
  onChange,
  mods,
  isReadOnly,
}: {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
  mods: Mods;
  isReadOnly?: boolean;
}) => {
  const [errors, setErrors] = useState<Record<string, any>>({});

  useEffect(() => {
    validate({ parameters, mods }).then(() => {
      setErrors({});
    }).catch((err) => {
      setErrors({ [err.path]: err.errors });
    });
  }, [parameters]);

  const minItemsLabel = fetchLabel('minItemsLabel', 'Minimum Items', mods);
  const maxItemsLabel = fetchLabel('maxItemsLabel', 'Maximum Items', mods);

  return (
    <>
      <div className="card-entry-row">
        <div className="card-entry">
          <h5>{minItemsLabel}</h5>
          <Input
            value={parameters.minItems || ''}
            key="minimum"
            type="number"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              onChange({
                ...parameters,
                minItems: parseInt(ev.target.value, 10),
              });
            }}
            className="card-modal-number"
            invalid={!!errors.minItems}
            readOnly={isReadOnly}
          />
          <FormFeedback valid={!errors.minItems}>
            {errors.minItems}
          </FormFeedback>
        </div>
        <div className="card-entry">
          <h5>{maxItemsLabel}</h5>
          <Input
            value={parameters.maxItems || ''}
            key="maximum"
            type="number"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              onChange({
                ...parameters,
                maxItems: parseInt(ev.target.value, 10),
              });
            }}
            className="card-modal-number"
            invalid={!!errors.maxItems}
            readOnly={isReadOnly}
          />
          <FormFeedback valid={!errors.maxItems}>
            {errors.maxItems}
          </FormFeedback>
        </div>
      </div>
      <MultipleChoice
        parameters={parameters.items}
        onChange={(newParams: Parameters) => {
          onChange({
            ...parameters,
            items: newParams,
          });
        }}
        mods={mods}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

const defaultFormInputs = {
  ...defaultInputs,
  ...shortAnswerInputs,
  ...longAnswerInputs,
  ...numberInputs,
} as Record<string, FormInput>;

defaultFormInputs.array = {
  displayName: 'Array',
  matchIf: [
    {
      types: ['array'],
    },
  ],
  defaultDataSchema: {
    items: {
      type: 'string',
    },
  },
  defaultUiSchema: {},
  type: 'array',
  cardBody: getInnerCardComponent({
    defaultFormInputs,
  }),
  modalBody: CardArrayParameterInputs,
};

export const arrayInputs: Record<string, FormInput> = {
  array: {
    displayName: 'Array',
    matchIf: [
      {
        types: ['array'],
      },
    ],
    defaultDataSchema: {
      items: {
        type: 'string',
      },
    },
    defaultUiSchema: {},
    type: 'array',
    cardBody: getInnerCardComponent({
      defaultFormInputs,
    }),
    modalBody: CardArrayParameterInputs,
  },
  checkboxes: {
    displayName: 'Checkboxes',
    matchIf: [
      {
        types: ['array'],
        widget: 'checkboxes',
      },
    ],
    defaultDataSchema: {
      items: {
        type: 'string',
        enum: [],
      },
      uniqueItems: true,
    },
    defaultUiSchema: {
      'ui:widget': 'checkboxes',
    },
    type: 'array',
    cardBody: CheckboxesChoice,
    modalBody: undefined,
  },
};
