import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from '@hotwired/stimulus';
import { ApolloProvider } from '@apollo/client';
import { WaitForImport } from '@/components/organisms/WaitForImport';
import { client } from '@/modules/client';

export default class extends Controller {
  // @ts-ignore
  declare idValue: string;

  // @ts-ignore
  declare redirectUrlValue: string;

  // @ts-ignore
  declare messageValue: string;

  // @ts-ignore
  declare errorMessageValue: string;

  // @ts-ignore
  declare successMessageValue: string;

  // @ts-ignore
  declare redirectLinkMessageValue: string;

  static values = {
    id: String,
    redirectUrl: String,
    message: String,
    errorMessage: String,
    successMessage: String,
    redirectLinkMessage: String,
  };

  static targets = ['canvas'];

  connect() {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <WaitForImport
          id={this.idValue}
          redirectUrl={this.redirectUrlValue}
          message={this.messageValue}
          errorMessage={this.errorMessageValue}
          successMessage={this.successMessageValue}
          redirectLinkMessage={this.redirectLinkMessageValue}
        />
      </ApolloProvider>,
      this.canvas,
    );
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.canvas);
  }

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
