import { DirectUploadDelegate } from '@rails/activestorage';

type ImageUploadSuccess = (url: string) => void;
type ImageUploadFailure = (err: string, options?: UploadFailureOptions) => void;
type ImageUploadProgress = (percent: number) => void;

export class ImageUploadHandler implements DirectUploadDelegate {
  success: ImageUploadSuccess;

  failure: ImageUploadFailure;

  progress?: ImageUploadProgress;

  blob_url_template: string;

  constructor(
    success: ImageUploadSuccess,
    failure: ImageUploadFailure,
    blob_url_template: string,
    progress?: ImageUploadProgress,
  ) {
    this.success = success;
    this.failure = failure;
    this.progress = progress;
    this.blob_url_template = blob_url_template;
  }

  /* eslint class-methods-use-this: 0 */
  /* eslint @typescript-eslint/no-unused-vars: 0 */
  directUploadWillStoreFileWithXHR(_xhr: XMLHttpRequest) {
    // TODO: this.progress(...)
  }

  directUploadDidComplete(error: Error, blob: ActiveStorage.Blob) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`);
    }

    const url = this.blob_url_template
      .replace(':signed_id', blob.signed_id)
      .replace(':filename', encodeURIComponent(blob.filename));

    this.success(url);
  }
}
