import { Controller } from '@hotwired/stimulus';
import bsCustomFileInput from 'bs-custom-file-input';

// Connects to data-controller="file-input"
export default class extends Controller {
  // @ts-ignore
  selectorValue: string;

  static values = {
    selector: { type: String, default: '.custom-file-input' },
  };

  /* eslint class-methods-use-this: 0 */
  connect() {
    bsCustomFileInput.init(this.selectorValue);
  }

  /* eslint class-methods-use-this: 0 */
  disconnect() {
    bsCustomFileInput.destroy();
  }
}
