import React, { useState, useEffect } from 'react';
import PasswordStrengthBar, {
  PasswordStrengthBarProps,
} from 'react-password-strength-bar';

interface PasswordStrengthMeterProps extends Partial<PasswordStrengthBarProps> {
  target?: string;
}

export const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  target = '',
  ...props
}) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!target) {
      return () => {};
    }

    const targetElement = document.querySelector(target);
    if (!targetElement) {
      return () => {};
    }

    const handleChange = (e: Event) => {
      setPassword((e.target as HTMLInputElement).value);
    };

    targetElement.addEventListener('input', handleChange);

    return () => {
      targetElement.removeEventListener('input', handleChange);
    };
  }, []);

  return <PasswordStrengthBar password={password} {...props} />;
};
