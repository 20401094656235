import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from '@hotwired/stimulus';

import { SurveyPreview } from '@/components/organisms/SurveyPreview';

export default class extends Controller {
  // @ts-ignore
  declare schemaValue;

  // @ts-ignore
  declare uiSchemaValue;

  static values = {
    schema: Object,
    uiSchema: Object,
  };

  static targets = ['schema', 'uiSchema', 'canvas'];

  connect() {
    ReactDOM.render(
      Object.keys(this.uiSchemaValue).length ? (
        <SurveyPreview
          schema={this.schemaValue}
          uiSchema={this.uiSchemaValue}
        />
      ) : <div className="alert alert-danger"><p>設問を設定してください。</p></div>,
      this.canvas,
    );
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.canvas);
  }

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
