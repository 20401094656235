import React, { useState, useEffect } from 'react';
import { useTemporaryFileQuery } from '@/generated/graphql';

type WaitForImportProps = {
  id: string;
  redirectUrl: string;
  message: string;
  errorMessage: string;
  successMessage: string;
  redirectLinkMessage: string;
};

const ProgressingSpinner = () => (
  <div className="spinner-border ml-4" role="status">
    <span className="sr-only">Progressing...</span>
  </div>
);

const Message = ({ message }: { message: string }) => (
  <>
    <p>{message}</p>
    <ProgressingSpinner />
  </>
);

const ErrorMessage = ({
  errorMessage,
  errorSummary,
}: {
  errorMessage: string;
  errorSummary: string;
}) => (
  <>
    <p>{errorMessage}</p>
    <blockquote className="blockquote">{errorSummary}</blockquote>
  </>
);

const SuccessMessage = ({
  redirectUrl,
  message,
  redirectLinkMessage,
}: {
  redirectUrl: string;
  message: string;
  redirectLinkMessage: string;
}) => (
  <>
    <p>{message}</p>
    <p>
      <a href={redirectUrl}>{redirectLinkMessage}</a>
    </p>
  </>
);

export const WaitForImport = (props: WaitForImportProps) => {
  const {
    id,
    redirectUrl,
    message,
    errorMessage,
    successMessage,
    redirectLinkMessage,
  } = props;
  const [isSuccess, setIsSuccess] = useState(false);
  const { data, stopPolling } = useTemporaryFileQuery({
    variables: { id },
    pollInterval: 1000,
  });

  useEffect(
    () => () => {
      stopPolling();
    },
    [],
  );

  if (data && !data.temporaryFile) {
    if (!isSuccess) {
      stopPolling();
      setIsSuccess(true);
    }
    return (
      <SuccessMessage
        redirectUrl={redirectUrl}
        message={successMessage}
        redirectLinkMessage={redirectLinkMessage}
      />
    );
  }

  const errorSummary = data?.temporaryFile?.errorSummary;
  if (errorSummary) {
    stopPolling();
    return (
      <ErrorMessage errorMessage={errorMessage} errorSummary={errorSummary} />
    );
  }

  return <Message message={message} />;
};
