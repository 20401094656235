import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dependent"
export default class extends Controller {
  // @ts-ignore
  declare triggerValue;

  // @ts-ignore
  declare checkedValue;

  // @ts-ignore
  declare uncheckedValue;

  static values = {
    trigger: String,
    checked: Boolean,
    unchecked: Boolean,
  };

  static targets = ['dependent', 'options'];

  connect() {
    this.optionsTargets.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', this.handleChange);
    });
  }

  disconnect() {
    this.optionsTargets.forEach((element: HTMLInputElement) => {
      element.removeEventListener('change', this.handleChange);
    });
  }

  handleChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const value = target.value === this.triggerValue ? this.checkedValue : this.uncheckedValue;
    this.dependentTarget.disabled = value;
  };

  get dependentTarget() {
    return this.targets.find('dependent') as HTMLInputElement;
  }

  get optionsTargets() {
    return this.targets.findAll('options') as Array<HTMLInputElement>;
  }
}
