import React from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

// eslint-disable-next-line no-unused-vars
import 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// Plugins
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from 'raw-loader!tinymce/skins/content/default/content.css';
import contentUiCss from 'raw-loader!tinymce/skins/ui/oxide/content.css';

// Languages
import language from 'tinymce-i18n/langs5/ja';

interface WysiwygEditorProps extends Partial<IAllProps> {
  imagesUploadUrl: string;
  blobUrlTemplate: string;
  images_upload_handler: UploadHandler;
}

export const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  init,
  images_upload_handler,
  ...props
}) => (
  <Editor
    {...props}
    init={{
      relative_urls: false,
      remove_script_host: true,
      skin: false,
      content_css: false,
      content_stylle: [contentCss, contentUiCss].join('\n'),
      plugins: 'image code table link advlist lists',
      toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
      language: 'ja',
      language_url: language,
      images_upload_handler,
      ...init,
    }}
  />
);
