import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

const doughnutOptions = {
  resizeDelay: 50,
};

const barOptions = {
  resizeDelay: 50,
  indexAxis: 'y' as const,
  scales: {
    x: {
      ticks: {
        callback: (value: any) => {
          if (Math.floor(value) === value) {
            return value;
          }

          return undefined;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

type Stats = { [key: string]: number };

type Data = {
  stats: Stats;
  multiple: boolean;
  labels: string[];
};

type Props = {
  data: Data;
};

function makeData(stats: Stats, labels: string[]): any {
  const data = Object.values(stats);

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          'rgba(255, 75, 0, 0.2)',
          'rgba(0, 90, 255, 0.2)',
          'rgba(3, 175, 122, 0.2)',
          'rgba(77, 196, 255, 0.2)',
          'rgba(246, 170, 0, 0.2)',
          'rgba(255, 241, 0, 0.2)',
          'rgba(0, 0, 0, 0.2)',
        ],
        borderColor: [
          'rgb(255, 75, 0)',
          'rgb(0, 90, 255)',
          'rgb(3, 175, 122)',
          'rgb(77, 196, 255)',
          'rgb(246, 170, 0)',
          'rgb(255, 241, 0)',
          'rgb(0, 0, 0)',
        ],
        borderWidth: 1,
      },
    ],
  };
}

const NoAnswer = () => <div>No answer</div>;

export const SurveyChart = ({ data }: Props) => {
  const { stats, multiple, labels } = data;
  const chartData = makeData(stats, labels);
  if (labels.length === 0) {
    return <NoAnswer />;
  }

  if (multiple) {
    return <Bar data={chartData} options={barOptions} />;
  }

  return <Doughnut data={chartData} options={doughnutOptions} />;
};
