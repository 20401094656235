import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="limited-checkbox"
export default class extends Controller {
  static targets = ['checkbox', 'role', 'group'];

  connect() {
    this.groupTargets.forEach((group) => group.addEventListener('click', this.limitRoleCheckbox));
    this.roleTargets.forEach((role) => role.addEventListener('click', this.limitGroupCheckbox));
    this.checkboxTargets.forEach((checkbox) => checkbox.addEventListener('change', this.refresh));

    if (this.groupChecked.length) this.limitRoleCheckbox();
    if (this.roleChecked.length) this.limitGroupCheckbox();
  }

  disconnect() {
    this.groupTargets.forEach((group) => group.removeEventListener('click', this.limitRoleCheckbox));
    this.roleTargets.forEach((role) => role.removeEventListener('click', this.limitGroupCheckbox));
    this.checkboxTargets.forEach((checkbox) => checkbox.removeEventListener('change', this.refresh));
  }

  refresh = () => {
    if (this.checked.length === 0) {
      this.checkboxTargets.forEach((checkbox) => {
        /* eslint no-param-reassign: 0 */
        checkbox.disabled = false;
      });
    }
  };

  limitGroupCheckbox = () => {
    this.groupTargets.forEach((checkbox) => this.disabledCheckbox(checkbox));
  };

  limitRoleCheckbox = () => {
    this.roleTargets.forEach((checkbox) => this.disabledCheckbox(checkbox));
  };

  disabledCheckbox = (checkbox: HTMLInputElement) => {
    /* eslint no-param-reassign: 0 */
    checkbox.checked = false;
    checkbox.disabled = true;
  };

  get checked(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get checkboxTargets(): HTMLInputElement[] {
    return this.targets.findAll('checkbox') as HTMLInputElement[];
  }

  get groupTargets(): HTMLInputElement[] {
    return this.targets.findAll('group') as HTMLInputElement[];
  }

  get roleTargets(): HTMLInputElement[] {
    return this.targets.findAll('role') as HTMLInputElement[];
  }

  get roleChecked(): HTMLInputElement[] {
    return this.roleTargets.filter((checkbox) => checkbox.checked);
  }

  get groupChecked(): HTMLInputElement[] {
    return this.groupTargets.filter((checkbox) => checkbox.checked);
  }
}
