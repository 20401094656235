import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** A block */
export type Block = {
  __typename?: 'Block';
  /** The depth of the block */
  depth: Scalars['Int'];
  /** The ID of the block */
  id: Scalars['ID'];
  /** Is the block a chapter? */
  isChapter: Scalars['Boolean'];
  /** The title of the block */
  title: Scalars['String'];
};

/** A company_admin */
export type CompanyAdmin = {
  __typename?: 'CompanyAdmin';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The email of CompanyAdmin. */
  email: Scalars['String'];
  /** The ID of CompanyAdmin */
  id: Scalars['ID'];
  /** Whether or not this CompanyAdmin is the owner of Company. */
  isOwner: Scalars['Boolean'];
  /** The login_name of CompanyAdmin. */
  loginName: Scalars['String'];
  /** The name of CompanyAdmin. */
  name: Scalars['String'];
  /** The english name of CompanyAdmin. */
  nameEnglish: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Update company_admin sample */
  updateCompanyAdminSample?: Maybe<UpdateCompanyAdminSamplePayload>;
};

export type MutationUpdateCompanyAdminSampleArgs = {
  input: UpdateCompanyAdminSampleInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  messages: Array<Scalars['String']>;
  path?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch a course */
  blocksByCourseId?: Maybe<Array<Block>>;
  /** Fetch temporary_file */
  temporaryFile?: Maybe<TemporaryFile>;
};

export type QueryBlocksByCourseIdArgs = {
  id: Scalars['ID'];
};

export type QueryTemporaryFileArgs = {
  id: Scalars['String'];
};

/** A temporary_file */
export type TemporaryFile = {
  __typename?: 'TemporaryFile';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** Error summary */
  errorSummary?: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of UpdateCompanyAdminSample */
export type UpdateCompanyAdminSampleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  loginName: Scalars['String'];
};

/** Autogenerated return type of UpdateCompanyAdminSample */
export type UpdateCompanyAdminSamplePayload = {
  __typename?: 'UpdateCompanyAdminSamplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyAdmin?: Maybe<CompanyAdmin>;
  errors?: Maybe<Array<MutationError>>;
};

export type TemporaryFileQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type TemporaryFileQuery = {
  __typename?: 'Query';
  temporaryFile?: {
    __typename?: 'TemporaryFile';
    errorSummary?: string | null;
    createdAt: any;
  } | null;
};

export type BlocksByCourseIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BlocksByCourseIdQuery = {
  __typename?: 'Query';
  blocksByCourseId?: Array<{
    __typename?: 'Block';
    id: string;
    title: string;
    depth: number;
    isChapter: boolean;
  }> | null;
};

export const TemporaryFileDocument = gql`
  query temporaryFile($id: String!) {
    temporaryFile(id: $id) {
      errorSummary
      createdAt
    }
  }
`;

/**
 * __useTemporaryFileQuery__
 *
 * To run a query within a React component, call `useTemporaryFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemporaryFileQuery,
    TemporaryFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryFileQuery, TemporaryFileQueryVariables>(
    TemporaryFileDocument,
    options,
  );
}
export function useTemporaryFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemporaryFileQuery,
    TemporaryFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemporaryFileQuery, TemporaryFileQueryVariables>(
    TemporaryFileDocument,
    options,
  );
}
export type TemporaryFileQueryHookResult = ReturnType<
  typeof useTemporaryFileQuery
>;
export type TemporaryFileLazyQueryHookResult = ReturnType<
  typeof useTemporaryFileLazyQuery
>;
export type TemporaryFileQueryResult = Apollo.QueryResult<
  TemporaryFileQuery,
  TemporaryFileQueryVariables
>;
export const BlocksByCourseIdDocument = gql`
  query blocksByCourseId($id: ID!) {
    blocksByCourseId(id: $id) {
      id
      title
      depth
      isChapter
    }
  }
`;

/**
 * __useBlocksByCourseIdQuery__
 *
 * To run a query within a React component, call `useBlocksByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlocksByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlocksByCourseIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlocksByCourseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BlocksByCourseIdQuery,
    BlocksByCourseIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlocksByCourseIdQuery, BlocksByCourseIdQueryVariables>(
    BlocksByCourseIdDocument,
    options,
  );
}
export function useBlocksByCourseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlocksByCourseIdQuery,
    BlocksByCourseIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BlocksByCourseIdQuery,
    BlocksByCourseIdQueryVariables
  >(BlocksByCourseIdDocument, options);
}
export type BlocksByCourseIdQueryHookResult = ReturnType<
  typeof useBlocksByCourseIdQuery
>;
export type BlocksByCourseIdLazyQueryHookResult = ReturnType<
  typeof useBlocksByCourseIdLazyQuery
>;
export type BlocksByCourseIdQueryResult = Apollo.QueryResult<
  BlocksByCourseIdQuery,
  BlocksByCourseIdQueryVariables
>;
