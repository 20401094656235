import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // @ts-ignore
  declare domainValue;

  static values = {
    domain: { type: String, default: '' },
  };

  static targets = [
    'subdomain',
  ];

  subdomainChanged() {
    const subdomain = this.subdomainTarget.value;
    if (subdomain) {
      window.location.host = `${subdomain}.${this.domainValue}`;
    } else {
      window.location.host = this.domainValue;
    }
  }

  get subdomainTarget(): HTMLSelectElement {
    return this.targets.find('subdomain') as HTMLSelectElement;
  }
}
